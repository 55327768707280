<template>
  <th-page-wrapper>
    <th-datatable
      :key="$route.fullPath"
      ref="processesTable"
      sortable
      :headers="headers"
      do-route
      do-route-filters
      :locale="locale"
      :show-operations="false"
      resource="processes"
      route-base="/inventory/processes"
      :buttons="computedButtons"
      show-search-filter
      :search-filters="filtersList"
      :resource-query="{
        query: {
          deleted: false,
          size: 100,
          fields: [
            'id',
            'name',
            'started_at',
            'finished_at',
            'status',
            'location'
          ]
        }
      }"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'ProcessesAll',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.processes')
    }
  },
  data() {
    return {
      locations: [],
      resourceQuery: {
        deleted: false
      },
      buttons: [
        {
          type: 'create',
          scopes: ['inventory:processes:create']
        },
        {
          type: 'custom_export',
          scopes: ['inventory:processes:export'],
          clickHandler: ({ resourceOptions }) => {
            this.handleExport({ resourceOptions })
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    translations() {
      return {
        created: this.$t('common.processes.statuses.created.title'),
        started: this.$t('common.processes.statuses.started.title'),
        paused: this.$t('common.processes.statuses.paused.title'),
        cancelled: this.$t('common.processes.statuses.cancelled.title'),
        finished: this.$t('common.processes.statuses.finished.title')
      }
    },
    headers() {
      return [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'started_at',
          label: this.$t('pages.processes.all.headers.started_at'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            if (!row.started_at) return '--'
            return this.$date.formatDateTimeWithTimezone(
              row.started_at,
              row.timezone
            )
          },
          sortType: 'date'
        },
        {
          field: 'finished_at',
          label: this.$t('pages.processes.all.headers.finished_at'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            if (!row.finished_at) return '--'
            return this.$date.formatDateTimeWithTimezone(
              row.finished_at,
              row.timezone
            )
          },
          sortType: 'date'
        },
        {
          field: 'status',
          label: this.$t('pages.processes.all.headers.status'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => this.translations[row?.status] || '-'
        },
        {
          field: 'location',
          label: this.$t('common.headers.location.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !row.location) return '-'
            if (!this.locations) return row.location
            return (
              (this.locations.find((item) => item.id === row.location) || {})
                .name || ' - '
            )
          }
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'status',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.processes.all.headers.status'),
          options: [
            'created',
            'started',
            'paused',
            'cancelled',
            'finished'
          ].map((status) => ({
            label: this.translations[status],
            value: status
          }))
        }
      ]
    }
  },
  async mounted() {
    try {
      await this.fetchLocations()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    fetchLocations: async function () {
      const { data } = await th.stocks().getLocations()
      this.locations = data || []
    },
    refresh() {
      this.$refs.processesTable.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.processes.title')
        })
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...this.resourceQuery,
        status: resourceOptions?.query?.status,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsProcesses.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.processes.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsProcesses',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.processes.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped></style>
